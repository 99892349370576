import React from "react"

import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import colors from "../styles/colors"
import dimensions from "../styles/dimensions"

import ProjectCard from "../components/_ui/ProjectCard"

const Grid = styled("div")`
  display: grid;
  grid-gap: 30px;

  grid-template-columns: 1fr 1fr;
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`
const AccidentalPicassoPrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Accidental Picasso Privacy Policy" />
      <div className="content title">
        <h1>Accidental Picasso Privacy Policy</h1>
        <Grid>
            <div>
                <h2>Information We Collect</h2>
                <p>
                    We collect your physical movement and voice data for use in the MR experience. This information is only used to provide the experience and is not stored. 
                    We do not collect any personally identifiable information such as your name, address, or contact information when you play Accidental Picasso.
                </p>
            </div>
            <div>
                <h2>How We Use Your Information</h2>
                <p>
                    All information collected is used only during the game and is not stored.
                </p>
            </div>
            <div>
                <h2>Customer Rights</h2>
                <p>
                    All users can request their data to be deleted by contacting us at <a href="mailto:angelicakosasih24@gmail.com">angelicakosasih24@gmail.com</a>.
                </p>
            </div>
        </Grid>
      </div>
    </Layout>
  )
}

export default AccidentalPicassoPrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
          description
          thumbnail {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`
